import { useEffect, useState } from 'react'
import { ApiData } from '../../ApiData'
import { fetchExchangeCurrencies } from '../../store/apiRequests/exchangeRequests'

type CryptoStocksProps1 = {
  defaultFiat: string
  defaultCrypto: string
}

type CryptoStocksReturn = {
  typeId: number
  setTypeId: (val: number) => void
  fiatSymbol: string
  cryptoSymbol: string
  setFiatSymbol: (val: string) => void
  setCryptoSymbol: (val: string) => void
  rate: { value: number, change: number }
  chart: { data: number[], labels: string[] }
  options: {
    fiat: string[]
    crypto: string[]
  },
}

export const useCryptoStocks = ({ defaultFiat, defaultCrypto }: CryptoStocksProps1): CryptoStocksReturn => {
  const [typeId, setTypeId] = useState(0)
  const [fiatSymbol, setFiatSymbol] = useState(defaultFiat)
  const [cryptoSymbol, setCryptoSymbol] = useState(defaultCrypto)
  const [options, setOptions] = useState({ fiat: [], crypto: [] })

  const [rate, setRate] = useState({ value: 0, change: 0 })
  const [chart, setChart] = useState({ data: [], labels: [] })

  useEffect(() => {
    const fetchCurrencies = async () => {
      const options = await fetchExchangeCurrencies()
      setOptions(options)
    }
    fetchCurrencies().then()
  }, [])

  useEffect(() => {
    const load = async () => {
      const { success, data: { rate, change } } = await ApiData.exchangeApiRequest({
        fiat_symbol: fiatSymbol,
        crypto_symbol: cryptoSymbol
      }, 'get_crypto_to_fiat_rate')

      if (success)
        setRate({ value: rate, change: parseFloat(change) })
    }
    load().then()
  }, [fiatSymbol, cryptoSymbol])

  useEffect(() => {
    const load = async () => {
      const { success, data: { time, rate } } = await ApiData.publicApiRequest({
        type_id: typeId,
        fiat_symbol: fiatSymbol,
        crypto_symbol: cryptoSymbol
      }, 'get_rates_history')

      if (success)
        setChart({ data: rate, labels: time })
    }
    load().then()
  }, [typeId, fiatSymbol, cryptoSymbol])

  return {
    typeId,
    setTypeId,
    fiatSymbol,
    cryptoSymbol,
    setFiatSymbol,
    setCryptoSymbol,
    rate,
    chart,
    options,
  }
}
