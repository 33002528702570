import { useForm, UseFormMethods } from 'react-hook-form'
import { useStoreClient } from '../../store/useStoreClient'
import { selectUser } from '../../store/selectors/ClientSelectors'
import { updateClientSettings } from '../../helpers/ClientHelpers'

type FormData = {
  first_name: string
  last_name: string
  email: string
  phone: string
  sex: '1' | '0'
}

type FormReturn = {
  onSubmit: (data: FormData) => void
  formMethods: UseFormMethods<FormData>
}

export const useSettingsInfoForm = <TFormValues extends Record<string, any> = Record<string, any>>(): FormReturn => {
  const client = useStoreClient(selectUser)
  const { first_name, last_name, email, phone, sex } = client
  const formMethods = useForm<FormData>({
    defaultValues: { first_name, last_name, email, phone, sex: sex === 1 ? '1' : '0' }
  })

  const onSubmit = async (data: FormData) => updateClientSettings({
    ...client,
    ...data,
    sex: parseInt(data.sex)
  })

  return {
    onSubmit,
    formMethods,
  }
}
