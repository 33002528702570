import { useEffect, useState } from 'react'
import { ExClient } from '../../ExClient'

type Wallet = {
  id: string
  name: string
  wallet: string
  client_id: string
}

type VerifyWalletFormReturn = {
  isLoaded: boolean
  cryptoList: Wallet[]
}

export const useVerifyWalletForm = (): VerifyWalletFormReturn => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [cryptoList, setCryptoList] = useState([])

  useEffect(() => {
    const load = async () => setCryptoList(await ExClient.getWallets())
    load().then(() => setIsLoaded(true))
  }, [])

  return {
    isLoaded,
    cryptoList,
  }
}
