import { useForm, UseFormMethods } from 'react-hook-form'
import { useStoreClient } from '../../store/useStoreClient'
import { selectUser } from '../../store/selectors/ClientSelectors'
import { updateClientSettings } from '../../helpers/ClientHelpers'

type FormData = {
  address: string
  city: string
  state: string
  zip: string
  country_code: string
}

type FormReturn = {
  onSubmit: (data: FormData) => void
  formMethods: UseFormMethods<FormData>
}

export const useSettingsAddressForm = <TFormValues extends Record<string, any> = Record<string, any>>(): FormReturn => {
  const client = useStoreClient(selectUser)
  const { address, city, state, zip, country_code } = client
  const formMethods = useForm<FormData>({
    defaultValues: { address, city, state, zip, country_code }
  })

  const onSubmit = async (data: FormData) => updateClientSettings({ ...client, ...data })

  return {
    onSubmit,
    formMethods,
  }
}
