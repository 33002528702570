import { useEffect, useMemo, useState } from "react";
import shallow from "zustand/shallow";
import { maskCardNumber } from "../../utils";
import { useTUCardStore } from "../../store/useTopUpCard";
import { useHistory } from "react-router-dom";

export const useByCrypto = () => {
  const history = useHistory();
  const [errors, setErrors] = useState<{
    deposit: boolean;
    card: boolean;
  } | null>(null);

  const {
    currencies,
    deposit,
    setCrypto,
    cards,
    fetchCurrencies,
    card,
  } = useTUCardStore(
    (state) => ({
      currencies: state.currencies,
      deposit: state.deposit,
      setCrypto: state.setCrypto,
      cards: state.cards,
      fetchCurrencies: state.fetchCurrencies,
      card: state.card,
    }),
    shallow
  );

  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies]);

  useEffect(() => {
    setErrors(null);
  }, [deposit, card]);

  const CARDS_OPTIONS = useMemo(
    () =>
      (Array.isArray(cards) ? cards : [])?.map((card) => ({
        value: card.id,
        label: maskCardNumber(card.card_pan),
      })) ?? [],
    [cards]
  );

  const goToNextStep = (path: string, crpyto: string) => {
    if (
      deposit === "" ||
      parseFloat(deposit) == 0 ||
      deposit == null ||
      card == null
    ) {
      setErrors({
        card: card == null,
        deposit: deposit === "" || deposit == null,
      });
    } else {
      setCrypto(crpyto);
      history.push(path);
    }
  };

  return {
    FIAT_OPTIONS: currencies.fiat,
    CARDS_OPTIONS,
    goToNextStep,
    errors,
  };
};
