export const maskCardNumber = (card_pan?: string) => {
  return card_pan
    ?.split("")
    .map((char, index) => {
      if (index > 3 && index < card_pan.length - 4) return "*";
      return char;
    })
    .join("");
};
export const clearStorage = () => {
  sessionStorage.clear();
};
