import { useEffect, useMemo, useState } from 'react'
import { DropzoneInputProps, DropzoneRootProps, useDropzone, DropzoneOptions } from 'react-dropzone'
import { ImgFile } from '../types/DropUploadTypes'

export interface DropUploadProps extends DropzoneOptions {
  baseStyle?: object
  activeStyle?: object
  acceptStyle?: object
  rejectStyle?: object
}

export type DropUploadReturn = {
  style: {}
  files: ImgFile[]
  setFiles?: (files: ImgFile[]) => void
  onDelete: (file: File) => void
  getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps
  getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps
}

export const useDropUpload = ({
  accept = {
    'image/*': ['.jpeg', '.png']
  },
  multiple = false,
  baseStyle = {},
  activeStyle = {},
  acceptStyle = {},
  rejectStyle = {},
  ...rest
}: DropUploadProps): DropUploadReturn => {
  const [files, setFiles] = useState<ImgFile[]>([])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept,
    multiple,
    onDrop: acceptedFiles => {
      const addFiles = acceptedFiles
        .filter((acceptedFile: File) => !files.find(file => file.name === acceptedFile.name))
        .map(file => Object.assign(file, { preview: URL.createObjectURL(file) }))

      setFiles(files => [...files, ...addFiles])
    },
    ...rest
  })

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [isDragActive, isDragReject, isDragAccept])

  useEffect(() => {
    return () => files.forEach(file => URL.revokeObjectURL(file.preview))
  }, [files])

  const onDelete = (delFile: ImgFile) =>
    setFiles(files => files.filter(file => file.name !== delFile.name))

  return {
    style,
    files,
    setFiles,
    onDelete,
    getRootProps,
    getInputProps,
  }
}
