import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useForm, UseFormMethods } from "react-hook-form";
import { MutationType } from "../../types/CardTypes";
import { useStoreCardView } from "../../store/useStoreCardView";
import { ExClient } from "../../ExClient";

type FormData = {
  password3ds: string;
  password3ds_confirm: string;
};

type Props<T extends object> = {
  onSuccess: () => void;
  msgSuccess?: string;
};

export const useCardPassword = <Mutation extends object>({
  onSuccess,
  msgSuccess = "Card PIN Code has been changed.",
}: Props<Mutation>) => {
  const { id } = useStoreCardView((state) => state.card);
  const [fetching, setFetching] = useState(false);
  const formMethods = useForm<FormData>();
  const changePINCode = useCallback(async () => {
    setFetching(true);
    const { password3ds: pin } = formMethods.getValues();
    const result = await ExClient.changePin({
      pin,
      card_id: Number(id),
    });
    if (typeof result === "string") {
      toast.error(result);
      setFetching(false);
      return;
    }
    if (result?.success) {
      toast.success(msgSuccess);
      setFetching(false);
      onSuccess();
    } else {
      toast.error(result.message);
    }
    setFetching(false);
  }, [id, formMethods]);

  return {
    fetching,
    formMethods,
    changePINCode,
  };
};
