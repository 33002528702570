import { useHistory } from "react-router-dom";
import { useTUCardStore } from "../../store/useTopUpCard";
import { useQRCode } from "../useQRCode";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ApiData, IApiResponse, ICreatePaymentResponse } from "../../ApiData";
import shallow from "zustand/shallow";
// import { useStoreClient } from "../../store/useStoreClient";
// import { selectUser } from "../../store/selectors/ClientSelectors";
// import { ClientType } from "../../types/CommonTypes";
import { ExClient } from "../../ExClient";
import { useStoreClient } from "../../store/useStoreClient";
import { selectUser } from "../../store/selectors/ClientSelectors";
import { ClientType } from "../../types/CommonTypes";

export const useInstruction = (
  isCreateCard: boolean = false,
  withSendHash: boolean = true
) => {
  const { client_type } = useStoreClient(selectUser);
  const isOnrampUser = client_type === ClientType.OnRamp;
  const [createLoading, setCreateLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const { generate, loading: QRLoading, result } = useQRCode();
  const history = useHistory();
  const {
    setWallet,
    card,
    crypto,
    cryptoAmount,
    deposit,
    fee,
    fiat,
    cardTypeId,
  } = useTUCardStore(
    (state) => ({
      card: state.card,
      cryptoAmount: state.cryptoAmount,
      crypto: state.crypto,
      deposit: state.deposit,
      fiat: state.fiat,
      fee: state.cryptoFee,
      setWallet: state.setWallet,
      cardTypeId: state.cardTypeId,
    }),
    shallow
  );
  const wallet = useTUCardStore((state) => state.wallet);

  const fetchDepositAddress = useCallback(async () => {
    setLoading(true);
    const response = await ApiData.getDepositAddress({
      crypto_amount: Number(cryptoAmount),
      crypto_currency: crypto,
      fiat_amount: Number(deposit),
      fiat_currency: fiat,
    });
    if (response?.success === false) toast.error(response?.message ?? "Error!");
    else setWallet(response.data.wallet);
    setLoading(false);
  }, [cryptoAmount, crypto, deposit, fiat, card]);

  const totalAmount = (parseFloat(cryptoAmount) + parseFloat(fee)).toFixed(5);
  const createDeposit = useCallback(async () => {
    setCreateLoading(true);
    const params = {
      card_id: card.id,
      crypto_amount: Number(cryptoAmount),
      crypto_currency: crypto,
      fiat_amount: Number(deposit),
      fiat_currency: fiat,
      wallet,
    };
    if (isCreateCard) Object.assign(params, { tr_type: "1" });
    let response: IApiResponse<ICreatePaymentResponse>;
    if (isOnrampUser) {
      response = await ExClient.processDeposit({
        amount: params.fiat_amount,
        crypto_amount: params.crypto_amount,
        crypto_currency: params.crypto_currency,
      });
    } else {
      response = await ApiData.createDeposit(params);
    }
    if (response?.success === false) {
      toast.error(response?.message ?? "Error!");
      setCreateLoading(false);
      return;
    }
    if (isOnrampUser && typeof response.data === "string") {
      window.location.replace(response.data);
    } else {
      const token = response.data.token;
      setCreateLoading(false);
      if (isMounted)
        history.push(
          withSendHash
            ? `instruction/hash/${token}`
            : `status/${response.data?.token}`
        );
    }
  }, [
    cryptoAmount,
    crypto,
    card,
    fiat,
    deposit,
    history,
    isMounted,
    isCreateCard,
    cardTypeId,
    wallet,
    isOnrampUser,
  ]);

  useEffect(() => {
    if (wallet != null) generate(wallet);
  }, [wallet, generate]);

  const copyToClipboard = (value: string) => {
    navigator.clipboard
      ?.writeText?.(value)
      .then(() => {
        toast.info("Copied to clipboard");
      })
      .catch(() => {
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    setIsMounted(true);
    fetchDepositAddress();
    return () => setIsMounted(false);
  }, [fetchDepositAddress, setIsMounted]);

  return {
    crypto,
    fiat,
    totalAmount,
    deposit: parseFloat(deposit).toFixed(2),
    cryptoAmount: parseFloat(cryptoAmount).toFixed(5),
    wallet,
    QRCode: result,
    loading: loading || QRLoading,
    copyToClipboard,
    createDeposit,
    fetchDepositAddress,
    createLoading,
    isOnrampUser,
  };
};
