import { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { Crop } from 'react-image-crop'
import { ExClient } from '../../ExClient'
import { useStoreClient } from '../../store/useStoreClient'
import { selectSetUser, selectUser } from '../../store/selectors/ClientSelectors'

const defaultCropState = {
  x: 0,
  y: 0,
  unit: 'px' as Crop['unit'],
  width: 0,
  height: 0,
  aspect: 1,
}

type CropReturn = {
  src: string
  crop: Crop
  cropFile: File
  onSave: () => void
  onApply: () => void
  onCancel: () => void
  onChange: (c: Crop) => void
  isUploading: boolean
  onImageLoaded: (img: HTMLImageElement) => void
}

export const useSettingsPhotoCrop = (): CropReturn => {
  const setUser = useStoreClient(selectSetUser)
  const { avatar } = useStoreClient(selectUser)

  const imgRef = useRef<HTMLImageElement>()
  const [src, setSrc] = useState(null)
  const [crop, setCrop] = useState<Crop>(defaultCropState)
  const [cropFile, setCropFile] = useState<File>(null)
  const [isUploading, setIsUploading] = useState(false)

  useEffect(() => {
    ExClient.getClientAvatar()
      .then(res => res.blob())
      .then(blob => setSrc(URL.createObjectURL(blob)))
  }, [avatar])

  const onSave = async () => {
    if (isUploading) return
    setIsUploading(true)

    const { success, message, data } = await ExClient.uploadAvatar(cropFile)
    setIsUploading(false)

    if (!success)
      return toast.error(message)

    onCancel()
    setUser({ avatar: data.path })
    return toast.info('Success!')
  }

  const onApply = () => {
    const image = imgRef.current
    const canvas = document.createElement('canvas')

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')
    const pixelRatio = window.devicePixelRatio

    canvas.width = crop.width * pixelRatio * scaleX
    canvas.height = crop.height * pixelRatio * scaleY

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    )

    const imgExt = avatar.split('.').pop()
    canvas.toBlob((blob) => {
      const newImage = new File([blob], `preview.${imgExt}`, { type: blob.type })
      newImage['preview'] = URL.createObjectURL(blob)
      setCropFile(newImage)
    }, `image/${imgExt.replace('jpg', 'jpeg')}`, 1)
  }

  const onCancel = useCallback(() => setCrop(defaultCropState), [])
  const onChange = (c: Crop) => setCrop(c)

  const onImageLoaded = useCallback((img: HTMLImageElement) => {
    imgRef.current = img
  }, [])

  return {
    src,
    crop,
    cropFile,
    onSave,
    onApply,
    onCancel,
    onChange,
    isUploading,
    onImageLoaded,
  }
}
