import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ExClient } from "../../ExClient";
import { ImgFile } from "../../types/DropUploadTypes";
import {
  DropUploadProps,
  DropUploadReturn,
  useDropUpload,
} from "../useDropUpload";
import { useStoreClient } from "../../store/useStoreClient";

interface BaseUploadFormProps extends DropUploadProps {
  type: string;
  onSuccess: (fileName: string) => JSX.Element;
}

interface BaseUploadFormReturn extends DropUploadReturn {
  error: string;
  errorFiles: ImgFile[];
  isUploaded: boolean;
  isUploading: boolean;
  onUpload: () => void;
}

export const useBaseUploadForm = ({
  type,
  onSuccess,
  baseStyle = {},
  activeStyle = {},
  acceptStyle = {},
  rejectStyle = {},
}: BaseUploadFormProps): BaseUploadFormReturn => {
  const { setTypes, uploadedTypes } = useStoreClient((state) => ({
    setTypes: state.setUploadedTypes,
    uploadedTypes: state.uploaded_types,
  }));
  const [error, setError] = useState("");
  const [errorFiles, setErrorFiles] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const {
    style,
    files,
    setFiles,
    getRootProps,
    getInputProps,
    onDelete,
  } = useDropUpload({
    accept: {
      "image/*": [],
      "document/*": [".pdf"],
    },
    multiple: true,
    baseStyle,
    activeStyle,
    acceptStyle,
    rejectStyle,
  });
  const onUpload = async () => {
    if (isUploading) return;
    if (files.length === 0) return setError("No files to upload!");

    setIsUploading(true);

    let isSuccess = true;
    let lastFilesWithError = [];

    for (const i in files) {
      const file = files[i];
      const { success } = await ExClient.uploadDocument(file, type);
      if (success) {
        toast.info(onSuccess(file.name), { autoClose: false });
      } else {
        toast.warn(`${file.name} is not uploaded.`);
        lastFilesWithError.push(file);
        isSuccess = false;
      }
    }

    if (isSuccess) {
      setError("");
      setIsUploaded(true);
      setIsUploading(false);
      const verificationResult = await ExClient.checkVerifications();
      if (verificationResult.success) setTypes(verificationResult.data);
    } else {
      setError("Error uploading documents to server");
      setIsUploaded(false);
      setIsUploading(false);
      setErrorFiles(lastFilesWithError);
    }
  };

  useEffect(() => {
    setFiles([]);
    setError("");
    setErrorFiles([]);
    setIsUploaded(false);
    setIsUploading(false);
  }, [type]);

  return {
    files,
    style,
    onDelete,
    getRootProps,
    getInputProps,

    error,
    errorFiles,
    isUploaded,
    isUploading,
    onUpload,
  };
};
