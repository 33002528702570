import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import { DropUploadProps, useDropUpload } from "../useDropUpload";
import { ExClient } from "../../ExClient";
import { useStoreClient } from "../../store/useStoreClient";
import { selectSetUser } from "../../store/selectors/ClientSelectors";

interface SettingsPhotoProps extends DropUploadProps {
  autoUpload?: boolean;
}

interface SettingsPhotoReturn {
  style: {};
  onUpload: () => void;
  upload: (file: File) => Promise<boolean>;
  isUploading: boolean;
  getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps;
  getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps;
}

export const useSettingsPhoto = ({
  autoUpload = true,
  baseStyle = {},
  activeStyle = {},
  acceptStyle = {},
  rejectStyle = {},
}: SettingsPhotoProps): SettingsPhotoReturn => {
  const setUser = useStoreClient(selectSetUser);
  const [isUploading, setIsUploading] = useState(false);

  const { style, files, getRootProps, getInputProps, onDelete } = useDropUpload(
    {
      accept: {
        "image/*": [".jpeg", ".png"],
      },
      multiple: false,
      baseStyle,
      activeStyle,
      acceptStyle,
      rejectStyle,
    }
  );

  useEffect(() => {
    if (files.length === 0 || !autoUpload) return;
    onUpload().then();
  }, [files, autoUpload]);

  const upload = useCallback(async (file: File): Promise<boolean> => {
    setIsUploading(true);

    const { success, message, data } = await ExClient.uploadAvatar(file);
    setIsUploading(false);

    if (!success) toast.error(message);
    else toast.info("Success!");

    if (success) setUser({ avatar: data.path });
    return success;
  }, []);

  const onUpload = async () => {
    if (isUploading) return;
    if (files.length === 0) return toast.error("No file to upload!");

    await upload(files[0]);
    onDelete(files[0]);
  };

  return {
    style,
    upload,
    onUpload,
    isUploading,
    getRootProps,
    getInputProps,
  };
};
