import { toast } from "react-toastify";
import { ExClient } from "../../ExClient";
import { useStoreCardView } from "../../store/useStoreCardView";
import { useMemo, useState } from "react";
import { useStoreClient } from "../../store/useStoreClient";
import shallow from "zustand/shallow";
import { CardStatus } from "src/types/CardTypes";

export const useCardFreeze = ({ onSuccess }: { onSuccess: () => void }) => {
  const [loading, setLoadign] = useState(false);
  const activeCards = useStoreClient((state) => state.activeCards);
  const {
    card: { id: card_id, status, maskedCardNumber: cardNumber, type },
    fetchCardDetails,
  } = useStoreCardView(
    (state) => ({
      card: state.card,
      fetchCardDetails: state.fetchCardDetails,
    }),
    shallow
  );
  const cannotUnlock = useMemo(
    () =>
      String(status) === "LOCKED" &&
      activeCards.some(
        (card) => card.card_type === type && card.status === "ACTIVE"
      ),
    [activeCards, status]
  );
  const fetchAction = async (actionType: "lock" | "unlock") => {
    setLoadign(true);

    const result = await ExClient.lockAction({
      card_id: Number(card_id),
      type: actionType,
    });
    if (typeof result === "string") {
      setLoadign(false);
      toast.error(result);
      return;
    }
    if (result?.success) {
      useStoreClient.getState().clearCache();
      await fetchCardDetails(Number(card_id), true);
      toast.success(`The card is ${actionType}ed`);
      onSuccess();
    } else {
      toast.error(result.message ?? "Something went wrong");
      onSuccess();
    }
    setLoadign(false);
  };

  const isBlocked = (status as string) === "LOCKED";
  return {
    fetchAction,
    isBlocked,
    loading,
    cardNumber,
    cannotUnlock,
  };
};
