import StateManager from "react-select";
import create, { State } from "zustand";
import { devtools } from "zustand/middleware";
import { CardStatus, CurrencyCode } from "../types/CardTypes";
import { ExClient } from "../ExClient";

interface Account {
  availableAmount: number;
  balance: number;
  blockedAmount: number;
  currencyCode: CurrencyCode;
}
export interface Card {
  account?: Account;
  embossingCompanyName: string;
  embossingFirstName: string;
  embossingLastName: string;
  embossingName: string;
  expiryDate: any;
  id: string;
  limits?: Limits;
  maskedCardNumber: string;
  name: string;
  status: CardStatus;
  type: string;
  balance: number;
  currency: string | null;
}
export type Limits = {
  dailyContactlessPurchase?: number;
  dailyContactlessPurchaseAvailable: number;
  dailyContactlessPurchaseUsed: number;
  dailyInternetPurchase?: number;
  dailyInternetPurchaseAvailable: number;
  dailyInternetPurchaseUsed: number;
  dailyOverallPurchase?: number;
  dailyOverallPurchaseAvailable: number;
  dailyOverallPurchaseUsed: number;
  dailyWithdrawal?: number;
  dailyWithdrawalAvailable: number;
  dailyWithdrawalUsed: number;
  monthlyContactlessPurchase?: number;
  monthlyContactlessPurchaseAvailable: number;
  monthlyContactlessPurchaseUsed: number;
  monthlyInternetPurchase?: number;
  monthlyInternetPurchaseAvailable: number;
  monthlyInternetPurchaseUsed: number;
  monthlyOverallPurchase?: number;
  monthlyOverallPurchaseAvailable: number;
  monthlyOverallPurchaseUsed: number;
  monthlyWithdrawal?: number;
  monthlyWithdrawalAvailable: number;
  monthlyWithdrawalUsed: number;
};
export interface CardViewStore extends State {
  card: Card;
  cardCache?: Record<string, Card>;
  cardLoading?: boolean;
  cardError?: string;

  cardCvv: string;
  cardRequisites: {
    pan?: string;
    cvv?: string;
  };
  cardNumber: string;
  panToggled?: boolean;
  cvvToggled?: boolean;
  setCardCvv: (value: string) => void;
  setCardNumber: (value: string) => void;
  setCardName: (name: string) => void;
  resetCard: VoidFunction;
  fetchCardDetails: (cardId: number, refetch?: boolean) => void;
  toggleCvv: (isOpen?: boolean) => void;
  togglePan: (isOpen?: boolean) => void;
  fetchCardCvv: VoidFunction;
  fetchCardPan: VoidFunction;
  startTimeOut: VoidFunction;
  timer?: NodeJS.Timeout | null;
}

const EMPTY_CARD = {
  card: {
    embossingCompanyName: null,
    embossingFirstName: null,
    embossingLastName: null,
    embossingName: null,
    expiryDate: null,
    id: null,
    maskedCardNumber: null,
    name: null,
    status: null,
    type: null,
    balance: 0,
    currency: null,
  } as Card,
  cardRequisites: {},
  cardCvv: "***",
  cardNumber: "",
};

export const useStoreCardView = create<CardViewStore>(
  devtools(
    (set, get) => ({
      ...EMPTY_CARD,
      fetchCardCvv: async () => {
        const cardId = get().card.id;
        let cvv = get().cardRequisites.cvv;
        if (cvv != null) {
          set({ cardCvv: cvv, cvvToggled: true });
        } else {
          const result = await ExClient.getCardCvv(Number(cardId));
          if (result.cvv != null)
            set({
              cardCvv: String(result.cvv),
              cvvToggled: true,
              cardRequisites: {
                ...get().cardRequisites,
                cvv: String(result.cvv),
              },
            });
        }
      },
      startTimeOut: () => {
        const timer = get().timer;
        if (timer != null) clearTimeout(timer);
        const timeOut = setTimeout(() => {
          set((state) => ({
            ...state,
            cardCvv: "***",
            cardNumber: get().card.maskedCardNumber,
            panToggled: false,
            cvvToggled: false,
            timer: null,
          }));
        }, 15000);
        set((state) => ({ ...state, timer: timeOut }));
      },
      fetchCardPan: async () => {
        const cardId = get().card.id;
        let pan = get().cardRequisites.pan;
        if (pan != null) set({ cardNumber: pan, panToggled: true });
        else {
          const result = await ExClient.getCardPan(Number(cardId));
          if (result.cardPan != null)
            set((state) => ({
              ...state,
              cardNumber: result.cardPan,
              panToggled: true,
              cardRequisites: { ...get().cardRequisites, pan: result.cardPan },
            }));
        }
        get().startTimeOut();
      },
      toggleCvv: (isOpen) => {
        const isToggled = get().cvvToggled;
        if ((isOpen && isToggled) || (isOpen == false && !isToggled)) return;
        if (!isToggled) get().fetchCardCvv();
        else
          set((state) => ({
            ...state,
            cardCvv: "***",
            cvvToggled: false,
          }));
        get().startTimeOut();
      },
      togglePan: (isOpen) => {
        const isToggled = get().panToggled;
        if ((isOpen && isToggled) || (isOpen == false && !isToggled)) return;
        if (!isToggled) get().fetchCardPan();
        else
          set((state) => ({
            ...state,
            panToggled: false,
            cardNumber: get().card.maskedCardNumber,
          }));
      },
      fetchCardDetails: async (cardId, refetch?: boolean) => {
        const cache = get().cardCache;
        if (cardId in (cache ?? {}) && !refetch) {
          set((state) => ({
            ...state,
            card: cache[cardId],
          }));
        } else {
          set((state) => ({ ...state, cardLoading: true }));
          const data = await ExClient.getCardDetails(cardId);
          if (data == null) {
            set((state) => ({
              ...state,
              card: null,
              cardLoading: false,
              cardError: "Something went wrong",
            }));
            return;
          }
          const limits = data.limits[0];
          const card = {
            id: data.id,
            expiryDate: data.expiryDate,
            maskedCardNumber: data.maskedCardNumber,
            name: data.cardName,
            status: data.status as CardStatus,
            type: data.type,
            embossingCompanyName: "",
            embossingFirstName: "",
            embossingLastName: "",
            embossingName: data.cardHolder,
            balance: Number(data.balance),
            currency: data.currency,
            limits: {
              dailyOverallPurchaseAvailable: Number(
                limits.daily_overall_purchase_available
              ),
              dailyOverallPurchaseUsed: Number(
                limits.daily_overall_purchase_used
              ),
              dailyOverallPurchase: Number(limits.daily_overall_purchase),
              monthlyOverallPurchaseAvailable: Number(
                limits.monthly_overall_purchase_available
              ),
              monthlyOverallPurchaseUsed: Number(
                limits.monthly_overall_purchase_used
              ),
              monthlyOverallPurchase: Number(limits.monthly_overall_purchase),

              dailyInternetPurchaseAvailable: Number(
                limits.daily_internet_purchase_available
              ),
              dailyInternetPurchaseUsed: Number(
                limits.daily_internet_purchase_used
              ),
              dailyInternetPurchase: Number(limits.daily_internet_purchase),
              monthlyInternetPurchaseAvailable: Number(
                limits.monthly_internet_purchase_available
              ),
              monthlyInternetPurchaseUsed: Number(
                limits.monthly_internet_purchase_used
              ),
              monthlyInternetPurchase: Number(limits.monthly_internet_purchase),

              dailyWithdrawalAvailable: Number(
                limits.daily_withdrawal_available
              ),
              dailyWithdrawalUsed: Number(limits.daily_withdrawal_used),
              dailyWithdrawal: Number(limits.daily_withdrawal_used),
              monthlyWithdrawalAvailable: Number(
                limits.monthly_withdrawal_available
              ),
              monthlyWithdrawalUsed: Number(limits.monthly_withdrawal_used),
              monthlyWithdrawal: Number(limits.monthly_withdrawal),

              dailyContactlessPurchaseAvailable: Number(
                limits.daily_pos_purchase_available
              ),
              dailyContactlessPurchaseUsed: Number(
                limits.daily_pos_purchase_used
              ),
              dailyContactlessPurchase: Number(limits.daily_pos_purchase),
              monthlyContactlessPurchaseAvailable: Number(
                limits.monthly_pos_purchase_available
              ),
              monthlyContactlessPurchaseUsed: Number(
                limits.monthly_pos_purchase_used
              ),
              monthlyContactlessPurchase: Number(limits.monthly_pos_purchase),
            },
          };
          set((state) => ({
            ...state,
            cardLoading: false,
            cardNumber: card.maskedCardNumber,
            cardCache: { ...state.cardCache, [cardId]: card },
            card,
          }));
        }
      },
      setCardCvv: (cardCvv: string) => set({ cardCvv }),
      setCardNumber: (cardNumber: string) => set({ cardNumber }),
      setCardName: (name: string) =>
        set((state) => ({ ...state, card: { ...state.card, name } })),
      resetCard: () => set(EMPTY_CARD),
    }),
    { name: "useStoreCardView" }
  )
);
