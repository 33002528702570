import { useCallback, useEffect, useState } from "react";
import { useStoreMain } from "../../store/useStoreMain";
import { useStoreClient } from "../../store/useStoreClient";
import {
  selectClientCards,
  selectSetUser,
  selectUser,
} from "../../store/selectors/ClientSelectors";
import {
  getIsAuthorized,
  selectInit,
} from "../../store/selectors/MainSelectors";
import { ExClient } from "../../ExClient";
import { ExClientInfo } from "../../ExClientInfo";
import { UrlUtils } from "../../utils/UrlUtils";
import { CLIENT_EVENTS, EventUtils } from "../../utils/EventUtils";
import { DefaultValues } from "../../DefaultValues";
import { Client, createClient } from "urql";
import { devtoolsExchange } from "@urql/devtools";
import { clearStorage } from "../../utils";
import shallow from "zustand/shallow";
import { ClientType } from "../../types/CommonTypes";

type ClientAppReturn = {
  isInitialized: boolean;
  client: Client;
  isCardActive?: boolean;
};

const createApolloClient = (isWizar?: boolean) =>
  createClient({
    url: process.env.REACT_APP_CARDS_API_URL,
    requestPolicy: "network-only",
    fetchOptions: () => {
      return {
        headers: {
          [isWizar ? "Authorization" : "auth"]: isWizar
            ? "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxIiwiZXhwIjoxODcwNDk5Mzk1LCJpYXQiOjE2NzA0OTkwOTV9.ngedNX4T5h1QaHPF5K74GGkawrRkTFyK_exhe-Fu4s0"
            : "1",
        },
      };
    },
  });

export const useClientApp = (isWizar?: boolean): ClientAppReturn => {
  const client = createApolloClient(isWizar);
  const init = useStoreMain(selectInit);
  const setUser = useStoreClient(selectSetUser);
  const fetchActiveCards = useStoreClient((state) => state.fetchActiveCards);
  const user = useStoreClient(selectUser);
  const isAuthorized = useStoreMain(getIsAuthorized);
  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    clearStorage();
    fetchActiveCards();
  }, []);
  useEffect(() => {
    init().then((loggedIn) => {
      if (!loggedIn) UrlUtils.redirectTo(`${DefaultValues.HOME}login`);
    });
  }, [init]);

  useEffect(() => {
    if (!isAuthorized) return;

    const unsubscribeClientInfoEvent = EventUtils.bus.subscribe(
      CLIENT_EVENTS.clientInfoUpdateEvent,
      (event: any) => {
        const data = event.payload;
        const {
          id,
          email,
          first_name,
          last_name,
          address,
          city,
          state,
          zip,
          country_code,
          country_name,
          phone,
          sex,
          verify_flags,
          date_created,
          date_last_login,
          avatar,
        } = data;
        setUser({
          id: parseInt(id),
          email,
          first_name,
          last_name,
          address,
          city,
          state,
          zip,
          country_code,
          country_name,
          phone,
          sex: parseInt(sex),
          is_verified: ExClientInfo.checkVerified(data),
          verify_flags,
          date_created,
          date_last_login,
          avatar,
          card_active: data.card_active,
          client_type:
            data?.client === "onramp" ? ClientType.OnRamp : ClientType.Default,
        });
      }
    );

    const unsubscribeClientLoggedOutEvent = EventUtils.bus.subscribe(
      CLIENT_EVENTS.clientLoggedOutEvent,
      (event: any) => {
        sessionStorage.setItem("loggedOut", "1");
        UrlUtils.redirectTo(`${DefaultValues.HOME}login`);
      }
    );

    ExClient.getClientInfo().then((data: ExClientInfo) => {
      if (data) {
        EventUtils.bus.publish(CLIENT_EVENTS.clientInfoUpdateEvent(data));
        EventUtils.bus.publish(
          CLIENT_EVENTS.clientLoggedEvent({ client_id: data.id })
        );
        setIsInitialized(true);
      }
    });

    return () => {
      unsubscribeClientInfoEvent();
      unsubscribeClientLoggedOutEvent();
    };
  }, [isAuthorized]);

  return {
    isInitialized,
    client,
    isCardActive: user.card_active,
  };
};
