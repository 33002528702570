export abstract class ArrayUtils {
    static GroupBy(key: string, data: Array<any>)
    {
        let result = {};
        for (let val of data)
        {
            if(!result[val[key]])
                result[val[key]] = [];

            result[val[key]].push(val);
        }
        return result;
    }

    static merge(first: any, second: any) :any {
        return {...first, ...second};
    }
}