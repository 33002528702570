import { useEffect, useMemo } from "react";
import shallow from "zustand/shallow";
import { useTUCardStore } from "../../store/useTopUpCard";

export const useCurrencyTable = (payment: number | null = null) => {
  const { prices, deposit, fiat, fetchPrices } = useTUCardStore(
    (state) => ({
      prices: state.prices,
      deposit: state.deposit,
      fiat: state.fiat,
      fetchPrices: state.fetchPrices,
    }),
    shallow
  );
  useEffect(() => {
    fetchPrices();
  }, [fetchPrices]);
  const E_Currencies = useMemo(() => {
    const depositBuffer = payment == null ? deposit : String(payment);
    return Object.keys(prices.crypto).map((symbol) => {
      const fee = prices?.fees?.find((fee) => fee.symbol === symbol);
      const spend = (
        parseFloat(depositBuffer || "0") *
        (prices.fiat[fiat] ?? 1) *
        (prices.crypto[symbol] ?? 1)
      ).toFixed(5);

      return {
        amount: depositBuffer,
        completion: 0,
        fee: fee?.commission ?? 0,
        spend,
        name: fee?.currency ?? "",
        symbol,
      };
    });
  }, [prices, deposit, fiat, deposit]);

  return { tableData: E_Currencies };
};
