import { toast } from 'react-toastify'
import { useForm, UseFormMethods } from 'react-hook-form'
import { ExClient } from '../../ExClient'

type FormData = {
  password: string
  password2: string
}

type FormReturn = {
  onSubmit: (data: FormData) => void
  formMethods: UseFormMethods<FormData>
}

export const useSettingsPasswordForm = <TFormValues extends Record<string, any> = Record<string, any>>(): FormReturn => {
  const formMethods = useForm<FormData>()

  const onSubmit = async (data: FormData) => {
    const { password, password2 } = data
    formMethods.clearErrors(['password2'])
    if (password !== password2)
      formMethods.setError('password2', { message: 'The passwords do not match.' })

    const { success, message } = await ExClient.updatePassword(password)
    if (!success)
      return toast.error(message)

    toast.success('Success!')
    formMethods.reset()
  }

  return {
    onSubmit,
    formMethods,
  }
}
