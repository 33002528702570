import { toast } from 'react-toastify'
import { ExClient } from '../ExClient'
import { ArrayUtils } from '../utils/ArrayUtils'
import { CLIENT_EVENTS, EventUtils } from '../utils/EventUtils'
import { User } from '../store/useStoreClient'

export const updateClientSettings = async (data: User) => {
  if (!data)
    return toast.error('Something wrong. Please contact to the support!')

  const { success, message } = await ExClient.updatePersonalInfo(data)
  if (!success)
    return toast.error(message)

  toast.success('Success!')
  EventUtils.bus.publish(CLIENT_EVENTS.clientInfoUpdateEvent(ArrayUtils.merge({}, data)))
}
