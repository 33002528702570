import { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm, UseFormMethods } from "react-hook-form";
import {
  CARD_DELIVERY_TYPES,
  CARD_TYPE_PHYSICAL,
  CARD_TYPE_VIRTUAL,
  CARD_TYPES,
} from "../../constants/CardConstants";
import {
  CountryCode,
  CurrencyCode,
  DeliveryType,
  MutationType,
} from "../../types/CardTypes";
import { AnyVariables } from "urql";
import { useStoreClient } from "../../store/useStoreClient";
import {
  selectClientCards,
  selectUser,
} from "../../store/selectors/ClientSelectors";
import { ExClient, ICreateCardResponse } from "../../ExClient";
import { useTUCardStore } from "../../store/useTopUpCard";
import shallow from "zustand/shallow";

type Delivery = {
  firstName: string;
  lastName: string;
  phone: string;
  companyName: string;
  countryCode: string;
  address1: string;
  address2: string;
  city: string;
  PostalCode: string;
  dispatchMethod: string;
};
type FormData = {
  company: string;
  nickname: string;
  type: string;
  name: string;
  password3ds: string;
  password3ds_confirm: string;
  dispatchMethod: string;
  use_company: boolean;
  pin: number;
  pin_confirm: number;
  username: string;
} & Delivery;

type Props<
  P extends object,
  V extends object,
  PVars = AnyVariables,
  VVars = AnyVariables
> = {
  physicalCard: {
    mutation: () => MutationType<P, PVars>;
    variables?: PVars;
  };
  virutalCard: {
    mutation: () => MutationType<V, VVars>;
    variables?: VVars;
  };
  onSuccess: (type: string) => void;
  msgSuccess?: string;
};

type Return = {
  onSubmit: (data: FormData) => void;
  formMethods: UseFormMethods<FormData>;
  isVirtual: boolean;
  cardTypes: string[];
  deliveryTypes: { label: string; value: string }[];
  isAllExist: boolean;
};

export const useCardCreate = <
  PhysicalCardM extends object,
  VirtualCardM extends object,
  PhysicalVariables = AnyVariables,
  VirtualVariables = AnyVariables
>({
  onSuccess,
  physicalCard,
  virutalCard,
  msgSuccess = "Card has been created.",
}: Props<
  PhysicalCardM,
  VirtualCardM,
  PhysicalVariables,
  VirtualVariables
>): Return => {
  const setCard = useTUCardStore((state) => state.setCreateCard);
  const user = useStoreClient((state) => state.user);
  const { cardTypes, isAllExist } = useStoreClient(selectClientCards, shallow);
  const formMethods = useForm<FormData>({
    defaultValues: {
      username: `${user.first_name} ${user.last_name}`,
      type: CARD_TYPE_PHYSICAL,
      dispatchMethod: DeliveryType.DhlExpress,
      use_company: true,
      countryCode: "United Kingdom",
      phone: user.phone,
    },
  });
  const setValue = formMethods.setValue;

  useEffect(() => {
    const type = [CARD_TYPE_PHYSICAL, CARD_TYPE_VIRTUAL].filter(
      (type) => !cardTypes.includes(type)
    );
    setValue("type", type.length === 0 ? "" : type[0]);
  }, [cardTypes, setValue]);

  const [, createCardVirtualMutation] = virutalCard.mutation();
  const [, createCardPhysicalMutation] = physicalCard.mutation();

  const createCardVirtual = useCallback(async () => {
    const { phone, nickname, password3ds } = formMethods.getValues();

    // return await createCardVirtualMutation({
    //   currencyCode: CurrencyCode.Eur,
    //   mobile,
    //   name: nickname,
    //   password3ds,
    //   ...virutalCard.variables,
    // });
    return await ExClient.createCard("create_virtual_card", {
      card_holder: `${user.first_name ?? ""} ${user.last_name ?? ""}`,
      card_name: nickname,
      card_type: 1,
      client_id: user.id,
      mobile: phone,
      pin: password3ds,
    });
  }, [formMethods, createCardVirtualMutation, user]);

  const createCardPhysical = useCallback(async () => {
    const {
      name,
      password3ds,
      pin,
      use_company,
      nickname,
      password3ds_confirm,
      type,
      username,
      pin_confirm,
      dispatchMethod,
      ...values
    } = formMethods.getValues();
    const deliveryTo: Delivery = use_company
      ? {
          firstName: user.first_name,
          lastName: user.last_name,
          address1: user.address,
          address2: user.address,
          city: user.city,
          PostalCode: user.zip,
          phone: user.phone,
          countryCode: user.country_code,
          companyName: "",
          dispatchMethod: dispatchMethod,
        }
      : { companyName: "", dispatchMethod, ...values };
    // return await createCardPhysicalMutation({
    //   currencyCode: CurrencyCode.Eur,
    //   deliveryTo,
    //   mobile: "+998941934001",
    //   name: nickname,
    //   password3ds: "qwertyIsStrongPassword",
    //   pin,
    //   printOnCard: nickname,
    //   ...physicalCard.variables,
    // });

    return await ExClient.createCard("create_physical_card", {
      card_holder: use_company
        ? `${user.first_name} ${user.last_name}`
        : `${values.firstName} ${values.lastName}`,
      card_name: nickname,
      card_type: 2,
      client_id: user.id,
      mobile: user.phone,
      pin: String(pin),
      deliveryTo,
    });
  }, [formMethods, createCardPhysicalMutation, user]);

  const onSubmit = async (data: FormData) => {
    let res: ICreateCardResponse & { success?: boolean; message?: string };
    if (data.type === CARD_TYPE_VIRTUAL) {
      res = await createCardVirtual();
    } else {
      res = await createCardPhysical();
    }
    if ("success" in res && res.success === false)
      return toast.error(res?.message ?? "Something went wrong");
    // toast.success(msgSuccess);
    setCard({ card_pan: res.cardPan, id: res.id }, res.cardType);
    onSuccess(`create_card_${data.type.toLowerCase()}`);
  };

  return {
    onSubmit,
    formMethods,
    isVirtual: formMethods.watch("type") === CARD_TYPE_VIRTUAL,
    cardTypes: CARD_TYPES,
    deliveryTypes: CARD_DELIVERY_TYPES,
    isAllExist,
  };
};
