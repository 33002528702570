import { useCallback, useEffect, useState } from 'react'
import { fetchExchangeCurrencies } from '../store/apiRequests/exchangeRequests'
import { Rate } from '../types/ExchangeTypes'
import { ApiData } from '../ApiData'

type AvailableAssetsProps = {
  initialFiat?: string
  initialCrypto?: string
}

type AvailableAssetsReturn = {
  options: {
    fiat: string[]
    crypto: string[]
  },
  fiat: string
  setFiat: (newValue: string) => void
  crypto: string
  setCrypto: (newValue: string) => void
  ratesFiat: Rate[]
  ratesCrypto: Rate[]
}

export const useAvailableAssets = ({
  initialFiat = 'USD',
  initialCrypto = 'BTC'
}: AvailableAssetsProps = {}): AvailableAssetsReturn => {
  const [options, setOptions] = useState({ fiat: [], crypto: [] })
  const [fiat, setFiat] = useState(initialFiat)
  const [crypto, setCrypto] = useState(initialCrypto)
  const [ratesFiat, setRatesFiat] = useState([])
  const [ratesCrypto, setRatesCrypto] = useState([])

  const fetchRates = useCallback(async (type: string, symbol: string) => {
    let { success, data } = await ApiData.exchangeApiRequest({ symbol }, `get_rates_${type}_symbol`)
    if (!success) return []
    return data
  }, [])

  useEffect(() => {
    const fetchCurrencies = async () => {
      const options = await fetchExchangeCurrencies()
      setOptions(options)
    }
    fetchCurrencies().then()
  }, [])

  useEffect(() => {
    const loadRates = async () => {
      const rates = await fetchRates('fiat', fiat)
      setRatesFiat(rates)
    }
    loadRates().then()
  }, [fiat])

  useEffect(() => {
    const loadRates = async () => {
      const rates = await fetchRates('crypto', crypto)
      setRatesCrypto(rates)
    }
    loadRates().then()
  }, [crypto])

  return {
    options,
    fiat,
    setFiat,
    crypto,
    setCrypto,
    ratesFiat,
    ratesCrypto,
  }
}
