import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useForm, UseFormMethods } from "react-hook-form";
import { ExchangeSession } from "../ExchangeSession";
import { ExClient } from "../ExClient";
import { ApiData } from "../ApiData";
import { History } from "history";

type FormData = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password: string;
  password2: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country_code: string;
  nationality: string;
  sex: "1" | "0";
  terms_accept: boolean;
  risk_accept: boolean;
  refund_accept: boolean;
  age_accept: boolean;
  captcha: string;
  resident: string;
};

type RegisterFormProps = {
  msgRegistered?: string;
  msgPasswordsMatch?: string;
};

type RegisterFormReturn = {
  onSubmit: (data: FormData) => void;
  formMethods: UseFormMethods<FormData>;
  captchaRef: React.MutableRefObject<any>;
  onCaptchaChange: (value: string) => void;
  modalProps: { isShow: boolean; email: string };
  modalOnClose: () => void;
  loading: boolean;
};

export const useRegisterForm = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  msgRegistered = "Registered!",
  msgPasswordsMatch = "The passwords do not match.",
}: RegisterFormProps = {}): RegisterFormReturn => {
  const [loading, setLoading] = useState(false);
  const formMethods = useForm<FormData>({
    defaultValues: {
      sex: "1",
      country_code: ExClient.ip_country_code,
    },
  });
  const { clearErrors, setError, setValue } = formMethods;

  const [modalProps, setModalProps] = useState({ isShow: false, email: "" });

  useEffect(() => {
    const fetchCountry = async () => {
      const country = await ExClient.getCountryCodeByIp(true);
      setValue("country_code", country);
    };
    fetchCountry().then();
  }, []);

  const modalOnClose = () => setModalProps({ isShow: false, email: "" });

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    const { email, captcha, password, password2 } = data;
    clearErrors(["password2"]);
    if (password !== password2)
      setError("password2", { message: msgPasswordsMatch });

    const body = {
      ...data,
      shop_id: ExClient.shop_id,
      "g-recaptcha-response": captcha,
    };
    const { success, message } = await ApiData.clientApiRequest(
      body,
      "register",
      null
    );

    captchaRef.current.reset();

    if (!success) {
      setLoading(false);
      return toast.error(message);
    }

    toast.success(msgRegistered);
    setModalProps({ isShow: true, email: email });
    formMethods.reset();

    await ExClient.sendRegEvent("sign_up");
    ExchangeSession.email_temp = email;
    setLoading(false);
  };

  const captchaRef = useRef(null);
  const onCaptchaChange = (value: string) => {
    setValue("captcha", value, { shouldValidate: true });
  };

  return {
    onSubmit,
    formMethods,
    captchaRef,
    onCaptchaChange,
    modalProps,
    modalOnClose,
    loading,
  };
};
