import { ExchangeSession } from "./ExchangeSession";
import {
  ApiData,
  ApiError,
  IApiResponse,
  checkAuth,
  getApiDataPromise,
} from "./ApiData";

import { UrlUtils } from "./utils/UrlUtils";
import { ExClientInfo } from "./ExClientInfo";
import { DefaultValues } from "./DefaultValues";
import { NetUtils } from "./utils/NetUtils";
import { ICardTransactionItem } from "./hooks/client/useCardTransactions";
import { IOAuthParams } from "./hooks/useLoginForm";

export enum Verification {
  VERIFICATION_PHONE_EXCLIENT = 1,
  VERIFICATION_EMAIL_EXCLIENT = 2,
  VERIFICATION_PASSPORT_EXCLIENT = 4,
  VERIFICATION_CARD_EXCLIENT = 8,
  VERIFICATION_UTILITY_BILL_EXCLIENT = 16,
  VERIFICATION_SELFIE_CARD_EXCLIENT = 32,
  VERIFICATION_SELFIE_PASSPORT_EXCLIENT = 64,
}

export interface CardTransactionsResult {
  data: ICardTransactionItem[];
  recordsTotal: number;
  success: boolean;
}
export interface IVerifiedSections {
  success: boolean;
  data: { type_id: string; exist: string }[];
  message: string;
}
///TODO-temporary interface
export interface ICreateCardParams {
  client_id: number;
  card_name: string;
  card_type: number;
  card_holder: string;
  pin: string;
  mobile: string;
  deliveryTo?: {
    firstName: string;
    lastName: string;
    phone: string;
    address1: string;
    address2: string;
    city: string;
    PostalCode: string;
  };
}
export interface ICreateCardResponse {
  id: string;

  card_pan: string;
  card_name: string;
  card_type: string;
  card_holder: string;
  cardPan?: string;
  cardName?: string;
  cardType?: string;
  cardHolder?: string;
  expiry: string;
  status: string;
}
export interface ICardLimits {
  id: string;
  card_id: string;
  daily_pos_purchase: string;
  daily_pos_purchase_available: string;
  daily_pos_purchase_used: string;
  monthly_pos_purchase: string;
  monthly_pos_purchase_available: string;
  monthly_pos_purchase_used: string;
  daily_internet_purchase: string;
  daily_internet_purchase_available: string;
  daily_internet_purchase_used: string;
  monthly_internet_purchase: string;
  monthly_internet_purchase_available: string;
  monthly_internet_purchase_used: string;
  daily_overall_purchase: string;
  daily_overall_purchase_available: string;
  daily_overall_purchase_used: string;
  monthly_overall_purchase: string;
  monthly_overall_purchase_available: string;
  monthly_overall_purchase_used: string;
  daily_withdrawal: string;
  daily_withdrawal_available: string;
  daily_withdrawal_used: string;
  monthly_withdrawal: string;
  monthly_withdrawal_available: string;
  monthly_withdrawal_used: string;
}
export interface ICardDetails {
  id: string;
  card_pan: string;
  cardName: string;
  maskedCardNumber: string;
  type: string;
  cardHolder: string;
  expiryDate: string;
  status: string;
  currency: string;
  balance: string;
  limits: ICardLimits[];
}
export interface ITransactionInfo {
  id: string;
  maskedCardNumber: string;
  status: string;
  currency: string;
  amount: number;
  fee: number;
  info: string | null;
  source: string;
  error_message: string | null;
  date_created: string;
  date_updated: string;
  ip: string | null;
  ex_card_transaction_id: string;
}
export interface ICardPan {
  cardPan: string;
  id: string;
}
export interface ICardCvv {
  cvv: number;
}
export interface ICard extends ICreateCardResponse {
  currency: string;
  balance: string;
}
export interface IClientCards {
  data: ICard[];
  recordsTotal: number;
}
export class ExClient {
  public static init(): void {
    const payment_id = UrlUtils.getUrlParam("p_id");
    if (payment_id) this.payment_id = payment_id;

    const payment_name = UrlUtils.getUrlParam("p_name");
    if (payment_name) this.payment_name = payment_name;

    const shop_id = UrlUtils.getUrlParam("s_id");
    if (shop_id) this.shop_id = shop_id;

    const transaction_id = UrlUtils.getUrlParam("tid");
    if (transaction_id) this.transaction_id = transaction_id;

    const offer_id = UrlUtils.getUrlParam("oid");
    if (offer_id) this.offer_id = offer_id;

    this.getCountryCodeByIp().then();
  }

  static get ip_country_code(): string {
    return localStorage.getItem("ex_client.ip_country_code");
  }

  static set ip_country_code(value: string) {
    if (value === undefined) throw new Error("Please enter valid value");
    localStorage.setItem("ex_client.ip_country_code", value);
  }

  static get shop_id(): string {
    return localStorage.getItem("ex_client.shop_id");
  }

  static set shop_id(value: string) {
    if (value === undefined) throw new Error("Please enter valid value");
    localStorage.setItem("ex_client.shop_id", value);
  }

  static get payment_id(): string {
    return localStorage.getItem("ex_client.payment_id");
  }

  static set payment_id(value: string) {
    if (value === undefined) throw new Error("Please enter valid value");
    localStorage.setItem("ex_client.payment_id", value);
  }

  static get payment_name(): string {
    return localStorage.getItem("ex_client.payment_name");
  }

  static set payment_name(value: string) {
    if (value === undefined) throw new Error("Please enter valid value");
    localStorage.setItem("ex_client.payment_name", value);
  }

  static get transaction_id(): string {
    return localStorage.getItem("ex_client.tid");
  }

  static set transaction_id(value: string) {
    if (value === undefined) throw new Error("Please enter valid value");
    localStorage.setItem("ex_client.tid", value);
  }

  static get offer_id(): string {
    return localStorage.getItem("ex_client.oid");
  }

  static set offer_id(value: string) {
    if (value === undefined) throw new Error("Please enter valid value");
    localStorage.setItem("ex_client.oid", value);
  }

  static get isAuthorized(): boolean {
    return Number(localStorage.getItem("ex_client.login_id")) > 0;
  }

  public static redirectLogin() {
    if (ExchangeSession.action != null) {
      let data = ExchangeSession.data;

      let url =
        "/" +
        ExchangeSession.action +
        "?step=" +
        (data["step"] ? data["step"] : 1);
      ExchangeSession.action = null;
      UrlUtils.redirectTo(url);
    } else UrlUtils.redirectTo("/client/");
  }

  public static async checkLogin(): Promise<any> {
    return NetUtils.getApiDataPromiseResultWithRetry(
      {},
      "client",
      "check_login"
    );
  }

  public static async extendSession(): Promise<any> {
    return NetUtils.getApiDataPromiseResultWithRetry(
      {},
      "client",
      "extend_session"
    );
  }
  public static async getCountryCodeByIp(refresh = false): Promise<string> {
    const savedCountryCode = this.ip_country_code;
    if (savedCountryCode === null || refresh) {
      let data = await this.getCountryByIp();
      if (data) {
        this.ip_country_code = data["code"] ?? "GB";
        return data["code"];
      } else return "";
    }

    return savedCountryCode;
  }

  public static async hasWallet(): Promise<boolean> {
    return NetUtils.getApiDataPromiseResultWithRetry(
      {},
      "exchange",
      "has_wallet"
    );
  }

  static async isValidWallet(
    wallet: string,
    crypto_currency: string,
    shop_id?: string
  ): Promise<boolean> {
    return NetUtils.getApiDataPromiseResultWithRetry(
      { wallet, crypto_currency, shop_id: shop_id || this.shop_id },
      "exchange",
      "is_valid_wallet"
    );
  }

  static async checkLimits(
    wallet: string,
    crypto_currency: string,
    fiat_amount: string,
    fiat_currency: string,
    shop_id?: string
  ): Promise<boolean> {
    return NetUtils.getApiDataPromiseResultWithRetry(
      {
        wallet,
        crypto_currency,
        fiat_amount,
        fiat_currency,
        shop_id: shop_id || this.shop_id,
      },
      "exchange",
      "check_limits"
    );
  }

  public static async canUpload(): Promise<boolean> {
    return NetUtils.getApiDataPromiseResultWithRetry(
      {},
      "client",
      "check_any_login"
    );
  }

  public static async getVerifyFlags() {
    return NetUtils.getApiDataPromiseResultWithRetry(
      {},
      "client",
      "get_verify_flags"
    );
  }

  public static async getVerifyInfo() {
    return NetUtils.getApiDataPromiseResultWithRetry(
      {},
      "client",
      "get_verify_info"
    );
  }

  static async verifyTempMail(data: any) {
    return await getApiDataPromise(data, "client", "verify_temp_email");
  }

  static async getPersonalInfo() {
    return NetUtils.getApiDataPromiseResultWithRetry(
      {},
      "client",
      "get_personal_info"
    );
  }

  static async getClientInfo(): Promise<ExClientInfo> {
    return NetUtils.getApiDataPromiseResultWithRetry(
      {},
      "client",
      "get_personal_info"
    );
  }

  public static sendHash(hash: string, token: string): Promise<any> {
    return getApiDataPromise({ tx: hash, token }, "exchange", "update_tx");
  }

  public static processDeposit(body: {
    amount: number;
    crypto_amount: number;
    crypto_currency: string;
  }): Promise<any> {
    return getApiDataPromise(body, "exchange", "process_deposit");
  }

  public static async downloadClientStatement({
    card_id,
    filter,
  }: {
    card_id?: number;
    filter?: Record<string, unknown>;
  }) {
    const formData: { card_id?: number; action: string } = {
      action: "get_card_statement",
    };
    if (card_id != null) Object.assign(formData, { card_id });
    if (filter != null) Object.assign(formData, { filter });
    const result = await fetch(DefaultValues.PAY + "api/exclient/cards.htm", {
      method: "POST",
      body: $.param(formData),
      headers: new Headers({
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      }),
      credentials: "include",
    });
    return result.blob();
  }

  static async getCardLast(
    cardId?: string,
    filter: Record<string, unknown> = {}
  ): Promise<{
    data: { recordsTotal: number; records: ICardTransactionItem[] };
  }> {
    const action = cardId != null ? "get_card_last" : "get_last";
    const body =
      cardId != null ? { ...filter, card_id: cardId } : { ...filter };
    return (ApiData.clientApiRequest(body, action, "cards") as any) as {
      data: {
        records: ICardTransactionItem[];
        recordsTotal: number;
      };
    };
  }

  static async getCardDetails(card_id: number): Promise<ICardDetails> {
    return (ApiData.clientApiRequest(
      { card_id },
      "get_card_details",
      "cards"
    ) as any) as ICardDetails;
  }

  static async getCardCvv(card_id: number): Promise<ICardCvv> {
    return (ApiData.clientApiRequest(
      { card_id },
      "get_card_cvv",
      "cards"
    ) as any) as ICardCvv;
  }
  static async getCardPan(card_id: number): Promise<ICardPan> {
    return (ApiData.clientApiRequest(
      { card_id },
      "get_card_pan",
      "cards"
    ) as any) as ICardPan;
  }
  static async getTransactionInfo(id: string): Promise<ITransactionInfo> {
    return (ApiData.clientApiRequest(
      { transaction_id: id },
      "get_card_transaction_details",
      "cards"
    ) as any) as ITransactionInfo;
  }
  static async editCardName(
    id: string,
    name: string
  ): Promise<IApiResponse<string>> {
    return ApiData.clientApiRequest(
      { card_id: id, card_name: name },
      "update_card_details",
      "cards"
    );
  }
  static async createCard(
    action: string,
    params: ICreateCardParams
  ): Promise<ICreateCardResponse> {
    return (ApiData.clientApiRequest(
      params,
      action,
      "cards"
    ) as any) as ICreateCardResponse;
  }

  static async activateCard({ card_id }: { card_id: number }) {
    return ApiData.clientApiRequest({ card_id }, "activate_card", "cards");
  }
  static async lockAction({
    card_id,
    type,
  }: {
    card_id: number;
    type: "lock" | "unlock";
  }) {
    return ApiData.clientApiRequest({ card_id }, `${type}_card`, "cards");
  }
  static async changePin({ card_id, pin }: { card_id: number; pin: string }) {
    return ApiData.clientApiRequest({ card_id, pin }, "set_card_pin", "cards");
  }
  static async getCards(
    onlyActive: boolean,
    offset?: number,
    count?: number
  ): Promise<IClientCards> {
    const filter: Record<string, string | number> = {};
    if (onlyActive) Object.assign(filter, { filter: { status: "ACTIVE" } });
    if (offset != null && count != null)
      Object.assign(filter, { count, offset });
    return (ApiData.clientApiRequest(
      filter,
      "get_user_cards_filter",
      "cards"
    ) as any) as IClientCards;
  }

  static async getWallets(): Promise<any> {
    return NetUtils.getApiDataPromiseResultWithRetry(
      {},
      "client",
      "get_wallets"
    );
  }

  public static isVerified(
    flags?: any,
    is_verified = false,
    is_default_shop = false
  ) {
    if (is_default_shop) return is_verified;

    if (flags !== false)
      return (
        flags & Verification.VERIFICATION_SELFIE_CARD_EXCLIENT &&
        flags & Verification.VERIFICATION_PASSPORT_EXCLIENT
      );

    return false;
  }
  public static async checkVerifications(): Promise<IVerifiedSections> {
    const data = new FormData();
    data.append("action", "get_uploaded_types");

    const result = await fetch(
      DefaultValues.PAY + "api/exclient/verifications.htm",
      {
        method: "POST",
        body: data,
        credentials: "include",
      }
    );

    const resultData = (await result.json()) as IVerifiedSections;
    return resultData;
  }
  public static async uploadDocument(file: any, type_id: string): Promise<any> {
    const data = new FormData();
    data.append("doc_upload", file);
    data.append("type_id", type_id);
    data.append("action", "upload_document");

    const result = await fetch(
      DefaultValues.PAY + "api/exclient/verifications.htm",
      {
        method: "post",
        body: data,
        credentials: "include",
      }
    );

    const resultJson = await result.json();
    checkAuth(resultJson);

    return resultJson;
  }

  public static async uploadAvatar(file: any): Promise<any> {
    const data = new FormData();
    data.append("file", file);
    data.append("action", "upload_avatar");

    const result = await fetch(
      DefaultValues.PAY + "api/exclient/settings.htm",
      {
        method: "post",
        body: data,
        credentials: "include",
      }
    );

    const resultJson = await result.json();
    checkAuth(resultJson);

    return resultJson;
  }

  static async getClientAvatar(): Promise<Response> {
    const data = new FormData();
    data.append("action", "get_avatar");

    return await fetch(DefaultValues.PAY + "api/exclient/settings.htm", {
      method: "post",
      body: data,
      credentials: "include",
    });
  }

  public static async login(
    email: string,
    password: string,
    oauthParams?: IOAuthParams
  ) {
    const result = await getApiDataPromise(
      {
        email: email,
        password: password,
        ...(oauthParams ?? {}),
      },
      "client",
      "login"
    );
    if (result?.data?.oauth_redirect_uri != null)
      return result.data.oauth_redirect_uri as string;
    if (result.success) return true;
    else {
      throw new ApiError(result.message, result.code);
    }
  }

  public static async register(data: any) {
    const result = await getApiDataPromise(data, "client", "register");

    if (result.success) return true;
    else throw new ApiError(result.message, result.code);
  }

  static async updatePassword(password: string) {
    return await getApiDataPromise(
      { password: password },
      "exclient/settings",
      "update_password"
    );
  }

  static async updatePersonalInfo(data: any) {
    return await getApiDataPromise(
      data,
      "exclient/settings",
      "update_personal_info"
    );
  }

  public static async sendRegEvent(where: string): Promise<void> {
    if (!this.offer_id || !this.transaction_id) return;

    const params = new URLSearchParams({
      offer_id: this.offer_id,
      transaction_id: this.transaction_id,
      more_info: where,
    });

    if (this.shop_id) params.append("source", this.shop_id);

    try {
      await fetch("https://up.billsfork.com/track_pb?" + params.toString());
    } catch (e) {
      console.error("can't send info about registration", e);
    }
  }

  static async logout() {
    localStorage.clear();
    let result = await getApiDataPromise({}, "client", "logout");
    if (result.success) UrlUtils.redirectTo(DefaultValues.HOME + "login");
  }

  public static async getCountryByIp(): Promise<{
    code: string;
    name: string;
    is_enabled: string;
    region_id: string;
  }> {
    const { data, success } = await ApiData.clientApiRequest(
      {},
      "get_country",
      null
    );
    return success
      ? data ?? []
      : { code: "", name: "", is_enabled: "0", region_id: "0" };
  }
}
