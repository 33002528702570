import { useEffect, useState } from "react";
import { History } from "history";
import { toast } from "react-toastify";
import { useForm, UseFormMethods } from "react-hook-form";
import { ApiData } from "../ApiData";
import { UrlUtils } from "../utils/UrlUtils";

type FormProps = {
  password: string;
  password2: string;
};

type RecoveryNewPasswordProps = {
  history: History;
};

type RecoveryNewPasswordReturn = {
  formMethods: UseFormMethods<FormProps>;
  onSubmit: (data: FormProps) => void;
  loading: boolean;
};

export const useRecoveryNewPassword = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  history,
}: RecoveryNewPasswordProps): RecoveryNewPasswordReturn => {
  const [loading, setLoading] = useState(false);
  const token = UrlUtils.getUrlParam("token");

  useEffect(() => {
    if (!token) history.push("/recovery");
  }, [history, token]);

  const formMethods = useForm<FormProps>();
  const onSubmit = async (data: FormProps) => {
    setLoading(true);
    const { password } = data;
    const { success, message } = await ApiData.clientApiRequest(
      { password, token },
      "reset_password",
      null
    );
    if (!success) {
      setLoading(false);
      return toast.error(message);
    }
    toast.success("Your password has been changed.");
    setLoading(false);
    history.push("/login");
  };

  return {
    formMethods,
    onSubmit,
    loading,
  };
};
